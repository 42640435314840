import setApi from '@/services/setApi';

const C = ( ( ) => {
    let parametros = {
        modulo  : 'soporte',
        abrev   : 'Sop',
        seccion : 'OPS',
        accion  : 'C',
        request : [
            /**
             * @description Inicializacion de datos de formularios para reportes 
             * su uso es dentro del store
             */
            "servicio",
            "categoria",
            "evento",
            "requerimiento",
            "ticket",
            "asignarTicket",
            "panel",
            "user"
        ]
    }    
    return setApi(parametros)
})()

export default C