import setApi from '@/services/setApi';

const R = ( ( ) => {
    let parametros = {
        modulo  : 'soporte',
        abrev   : 'Sop',
        seccion : 'OPS',
        accion  : 'R',
        request : [
            "dataSourceTickets",
            "dataSourceServicios",
            "dataUsers",
            "dataSourceCategorias",
            "dataSourceEventos",
            "dataCategorias",
            "dataSourceRequerimiento",
            "dataCreateTicket",
            "dataAsignarTicket",
            "dataCierreTicket",
            "dataAsignacion",
            "initFormTicket",
            "getDataDashboard",
            "dataSourceUsers",
            "dataCreateUser",
            "dataInfoTicket"
        ]
    }    
    return setApi(parametros)
})()

export default R