import setApi from '@/services/setApi';

const C = ( ( ) => {
    let parametros = {
        modulo  : 'soporte',
        abrev   : 'Sop',
        seccion : 'OPS',
        accion  : 'U',
        request : [
            /**
             * @description Inicializacion de datos de formularios para reportes 
             * su uso es dentro del store
             */
            "servicio",
            "eliminarServicio",
            "activarServicio",
            "categoria",
            "eliminarCategoria",
            "activarCategoria",
            "evento",
            "eliminarEvento",
            "activarEvento",
            "requerimiento",
            "eliminarRequerimiento",
            "activarRequerimiento",
            "ticket",
            "actulizarTicket",
            "atenderTicket",
            "closeTicket",
            "eliminarTicket",
            "cancelarTicket",
            "pausarTicket",
            "finalizarTicket",
            "actulizarTicketGeneral"
        ]
    }    
    return setApi(parametros)
})()

export default C